import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Slideshow.css";

const Slideshow = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="slideshow-container">
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        showDots={true}
        customLeftArrow={<div className="custom-left-arrow">&#10094;</div>}
        customRightArrow={<div className="custom-right-arrow">&#10095;</div>}
      >
        {[
          "/WhatsApp Image 2024-10-02 at 15.53.17.jpeg",
          "/WhatsApp Image 2024-10-02 at 16.11.01.jpeg",
          "/WhatsApp Image 2024-10-02 at 14.31.13.jpeg",
          "/WhatsApp Image 2024-10-02 at 16.22.55.jpeg",
          "/WhatsApp Image 2024-10-02 at 16.28.02.jpeg",
          "/WhatsApp Image 2024-10-02 at 16.40.03.jpeg",
          "/WhatsApp Image 2024-10-02 at 16.36.07.jpeg",
        ].map((src, index) => (
          <div key={index}>
            <img src={src} alt={`Slide ${index + 1}`} className="slideshow-image" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Slideshow;
